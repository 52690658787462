import './App.css';
import logo from './tube.svg';
import crop from './crop.png';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux'

import { click, buy, upgrade } from './slice';

import LabView from './LabView';

import { observationsPerSecond, farmItems, canAffordLabItem } from './const';
import { upgrades } from './const.mjs';

function App() {

  const gameState = useSelector((state) => state.game)

  const dispatch = useDispatch()

  const formatNumber = number => {
    return number.toLocaleString()
  }

  const displayContainerHelp = gameState.observations === 0
  const displayAutomationHelp = gameState.observations >= 10 && observationsPerSecond(gameState.automationCounts) == 0

  const visableLabItems = farmItems.filter(item => item.cost((gameState.automationCounts[item.id] || 0) + 1) <= gameState.maxObservations * 3).filter(item => item.requires ? gameState.upgrades.includes(item.requires) : true);

  const nextUpgrade = upgrades.find(upgrade => !gameState.upgrades.includes(upgrade.id))

  return (
    <div className="app">
      <div className="app-section">
        <div className="title">Recursion FARMaceuticals</div>
        <div className="observations">$ {formatNumber(Math.floor(gameState.observations))}</div>
        <div className="observations-per-second">${formatNumber(observationsPerSecond(gameState.automationCounts))}/day</div>
        <div className="container" onClick={() => dispatch(click())}><img src={crop} width="100%" /></div>
        {displayContainerHelp && (<div className="help-section">Hint: Click above to generate observations</div>)}
        {nextUpgrade ?
          (<div className="upgrade-item">{nextUpgrade.label} ${formatNumber(nextUpgrade.cost)}<button onClick={() => dispatch(upgrade({ id: nextUpgrade.id }))}>BUY</button></div>) :
          (<div className="upgrade-item">For further upgrades to industrialize drug discovery, visit <a href="https://rxrx.ai">RXRX</a></div>)}
      </div>
      <div className="app-section">
        <LabView />
        <div className="automation-list">
          {visableLabItems.map(item => (
            <div key={item.id} className="automation-item">
              <button
                className="automation-item-button"
                disabled={!canAffordLabItem(gameState.observations, item.id, 1, gameState.automationCounts[item.id] || 0, gameState.upgrades)}
                onClick={() => dispatch(buy({ id: item.id, count: 1 }))}>
                Buy
              </button>
              <div className="title">{item.label} x {formatNumber(gameState.automationCounts[item.id] || 0)}</div>
              <div className="subtitle"> Produces ${formatNumber(item.observationsPerSecond())}/day</div>
              <div className="subtitle"> Costs ${formatNumber(item.cost((gameState.automationCounts[item.id] || 0) + 1))}</div>
            </div>
          ))}
        </div>
        {displayAutomationHelp && (<div className="help-section">Hint: Purchase items above with observations to generate observations automatically.</div>)}
      </div>
    </div>
  );
}

export default App;
