const itemBaseCost = (itemNum) => Math.floor(Math.pow(4.5, itemNum)) * 100
const itemBaseOps = itemNum => Math.floor(Math.pow(2.5, itemNum)) * 10

export const farmItems = [{
    id: 'worker',
    label: "Worker",
    observationsPerSecond: () => itemBaseOps(0),
    cost: (count) => {
        return Math.floor(Math.pow(1.15, count - 1) * itemBaseCost(0));
    },
    requires: 'workers-house',
},
{
    id: 'cow',
    label: "Cow",
    observationsPerSecond: () => itemBaseOps(1),
    cost: (count) => {
        return Math.floor(Math.pow(1.15, count - 1) * itemBaseCost(1));
    },
    requires: 'cow-barn',
},
{
    id: 'tractor',
    label: "Tractor",
    observationsPerSecond: () => itemBaseOps(2),
    cost: (count) => {
        return Math.floor(Math.pow(1.15, count - 1) * itemBaseCost(2));
    },
    requires: 'tractor-garage',
},
{
    id: 'combine-harvester',
    label: "Combine Harvester",
    observationsPerSecond: () => itemBaseOps(3),
    cost: (count) => {
        return Math.floor(Math.pow(1.15, count - 1) * itemBaseCost(3));
    },
    requires: 'combine-harvester-garage',
}]

export const upgrades = [{
    id: 'workers-house',
    label: "Workers House",
    cost: 100,
},
{
    id: 'cow-barn',
    label: "Cow Barn",
    cost: 5000,
},
{
    id: 'tractor-garage',
    label: "Tractor Garage",
    cost: 50000,
},
{
    id: 'combine-harvester-garage',
    label: "Combine Harvester Garage",
    cost: 500000,
}]

export function observationsPerSecond(automationCounts, upgrades = []) {
    return Object.keys(automationCounts).reduce((acc, key) => acc + (automationCounts[key] * farmItems.find(x => x.id === key).observationsPerSecond()), 0)
}


export function labItemCost(id, count, currentCount) {
    const item = farmItems.find(x => x.id === id)
    return (Array.from(Array(count).keys())
        .map(x => x + currentCount + 1)
        .map(x => item.cost(x))
        .reduce((acc, x) => acc + x)
    )
}

export function canAffordLabItem(currentObservrations, id, count, currentCount, upgrades = []) {
    const cost = labItemCost(id, count, currentCount)
    const farmItem = farmItems.find(x => x.id === id)

    if (!upgrades.includes(farmItem.requires)) {
        return false
    }
    return currentObservrations >= cost
}

export function canAffordUpgrade(currentObservrations, upgrade) {
    return currentObservrations >= upgrades.find(x => x.id == upgrade).cost
}


export default {
    farmItems,
    upgrades,
    observationsPerSecond,
    labItemCost,
    canAffordLabItem,
    canAffordUpgrade,
}